import React from 'react';

import './hero.scss';

function Hero() {
  const customImages = ['hero', 'garden'];
  const text = process.env.REACT_APP_HERO_ALT;
  const brand = process.env.REACT_APP_BRAND_NAME;
  return (
    <div className="hero">
      {customImages.map((name) => {
        let path = '/images/' + brand + '/' + name + '.jpg';
        let mobileUrl = path.replace('.jpg', '_mobile.jpg');
        return (
          <img
            className={`${name}Img`}
            key={name}
            src={path}
            srcSet={`${mobileUrl} 1943w,${path} 2200w`}
            sizes="(max-width: 640px) 100vw, 2200px"
            alt={text}
          />
        );
      })}
    </div>
  );
}
export default Hero;
